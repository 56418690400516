import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import ChannelFilter from './ChannelFilter';

function App() {
  const [searchTerm, setSearchTerm] = useState("");
  const [query, setQuery] = useState(""); // Start with an empty string
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [selectedChannelIds, setSelectedChannelIds] = useState([]);
  const [initialized, setInitialized] = useState(false); // Track whether initial load is done

  // Hooks from react-router-dom v6
  const navigate = useNavigate();
  const location = useLocation();

  // Create a reference for the search input
  const searchInputRef = useRef(null);

  // Automatically focus on the search bar when the page loads
  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  // Set up the searchTerm and selectedChannelIds based on the URL query parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryParam = params.get("query");
    const channelIdsParam = params.get("channel_ids");

    if (queryParam) {
      setQuery(queryParam);
      setSearchTerm(queryParam);
    } else if (!initialized) {
      // Only set the default if there was no query parameter in the URL AND it's the first time loading
      setQuery("мой олега кашина");
      setSearchTerm(""); // Ensure search bar is empty
    }

    if (channelIdsParam) {
      setSelectedChannelIds(channelIdsParam.split(','));
    }

    // Mark as initialized after first render
    setInitialized(true);
  }, [location.search, initialized]);

  // Add a keyboard shortcut to focus on the search bar when "/" is pressed
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === '/' && document.activeElement !== searchInputRef.current) {
        event.preventDefault();
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Fetch videos from backend whenever `query` or `selectedChannelIds` change
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const fetchData = async () => {
        if (query.trim() === "" && selectedChannelIds.length === 0) {
          setFilteredVideos([]);
          return;
        }

        try {
          const queryParams = new URLSearchParams();

          if (query.trim() !== "") {
            queryParams.append("query", query);
          }

          if (selectedChannelIds.length > 0) {
            queryParams.append("channel_ids", selectedChannelIds.join(','));
          }

          const response = await fetch(`/search?${queryParams.toString()}`);

          if (response.ok) {
            const data = await response.json();
            if (Array.isArray(data)) {
              setFilteredVideos(data);
            } else {
              setFilteredVideos([]);
            }
          } else {
            console.error("Error fetching data from the backend");
          }
        } catch (error) {
          console.error("Network error:", error);
        }
      };

      fetchData();
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [query, selectedChannelIds]);

  // Update the URL whenever the query or selectedChannelIds change
  useEffect(() => {
    const params = new URLSearchParams();

    if (query.trim() !== "") {
      params.append("query", query);
    }

    if (selectedChannelIds.length > 0) {
      params.append("channel_ids", selectedChannelIds.join(','));
    }

    navigate({ search: params.toString() }, { replace: true });
  }, [query, selectedChannelIds, navigate]);

  // Update the search term state when user types in the search bar
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Update the query when user presses Enter in the search bar
  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter') {
      setQuery(searchTerm);
    }
  };

  return (
      <div className="container">
        {/* Search bar and filter icon container */}
        <div
            className="search-wrapper"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '20px',
              paddingLeft: '110px'
            }}
        >
          {/* Inner container to hold search bar and icon */}
          <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            <div className="search-input-container" style={{ maxWidth: '600px' }}>
              <input
                  type="search"
                  placeholder="мой олега кашина"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onKeyDown={handleSearchSubmit} // Trigger search on Enter key press
                  ref={searchInputRef}
                  style={{ width: '100%', padding: '10px 15px', borderRadius: '8px' }}
              />
            </div>

            {/* Use the ChannelFilter component next to the search bar */}
            <div style={{ marginLeft: '10px', cursor: 'pointer' }}>
              <ChannelFilter
                  selectedChannelIds={selectedChannelIds}
                  setSelectedChannelIds={setSelectedChannelIds}
              />
            </div>
          </div>
        </div>

        {/* Results table */}
        <table className="table">
          <thead>
          <tr>
            <th>Preview</th>
            <th>Text</th>
          </tr>
          </thead>
          <tbody>
          {filteredVideos.length > 0 ? (
              filteredVideos.map((video, index) => (
                  <tr key={index}>
                    <td>
                      <iframe
                          width="560"
                          height="315"
                          src={video.youtube_preview_url}
                          title={`YouTube video player`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                          loading="lazy"
                      ></iframe>
                    </td>
                    <td
                        style={{
                          color: '#111',
                          fontWeight: '500'
                        }}
                        dangerouslySetInnerHTML={{ __html: video.fulltext }}
                    ></td>
                  </tr>
              ))
          ) : (
              <tr>
                <td colSpan="2" style={{ textAlign: 'center' }}>No results found</td>
              </tr>
          )}
          </tbody>
        </table>
      </div>
  );
}

export default App;