// ChannelFilter.js
import React, { useState, useEffect, useRef } from 'react';
import { FiFilter } from 'react-icons/fi';
import './App.css';

const ChannelFilter = ({ selectedChannelIds, setSelectedChannelIds }) => {
    const [channels, setChannels] = useState([]);
    const [filteredChannels, setFilteredChannels] = useState([]);
    const [channelSearchTerm, setChannelSearchTerm] = useState("");
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    // Fetch channels from the backend
    useEffect(() => {
        const fetchChannels = async () => {
            try {
                const response = await fetch('/channels');
                if (response.ok) {
                    const data = await response.json();

                    // Ensure data is an array and not null/undefined´
                    const channelsData = Array.isArray(data) ? data : [];

                    setChannels(channelsData);
                    setFilteredChannels(channelsData);
                } else {
                    console.error('Error fetching channels');
                    setChannels([]); // Set to empty array on error
                    setFilteredChannels([]);
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        };

        fetchChannels();
    }, []);

    // Handle channel checkbox change
    const handleChannelCheckboxChange = (channelId) => {
        setSelectedChannelIds((prevSelectedIds) =>
            prevSelectedIds.includes(channelId)
                ? prevSelectedIds.filter(id => id !== channelId)
                : [...prevSelectedIds, channelId]
        );
    };

    // Handle the search input inside the dropdown
    const handleChannelSearchChange = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setChannelSearchTerm(searchValue);

        // Filter channels based on the search term
        const filtered = channels.filter(channel =>
            channel.channel_alias.toLowerCase().includes(searchValue)
        );

        setFilteredChannels(filtered);
    };

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
        if (!isDropdownVisible) {
            setFilteredChannels(channels);
            setChannelSearchTerm('');
        }
    };

    // Hide the dropdown if clicking outside
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownVisible(false);
        }
    };

    // Register and cleanup event listener for clicks outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="dropdown" style={{ position: 'relative', display: 'inline-block' }}>
            <FiFilter
                size={24}
                onClick={toggleDropdown}
                style={{ cursor: 'pointer', color: '#333', marginTop: '12px' }}
                title="Filter Channels"
            />

            <div
                ref={dropdownRef}
                className="dropdown-content"
                style={{
                    display: isDropdownVisible ? 'block' : 'none',
                    position: 'absolute',
                    left: '-12px',
                    top: '40px',
                    backgroundColor: '#fff',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    zIndex: 100,
                    width: '250px',
                    maxHeight: '250px',
                    overflowY: 'auto',
                    boxSizing: 'border-box',
                }}
            >
                <input
                    type="text"
                    placeholder="Search channels..."
                    value={channelSearchTerm}
                    onChange={handleChannelSearchChange}
                    style={{
                        width: '100%',
                        padding: '5px',
                        marginBottom: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd',
                        boxSizing: 'border-box',
                    }}
                />

                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {filteredChannels.length > 0 ? (
                        filteredChannels.map((channel) => (
                            <label key={channel.channel_id} style={{ display: 'block', margin: '5px 0' }}>
                                <input
                                    type="checkbox"
                                    checked={selectedChannelIds.includes(channel.channel_id)}
                                    onChange={() => handleChannelCheckboxChange(channel.channel_id)}
                                />
                                {channel.channel_alias}
                            </label>
                        ))
                    ) : (
                        <div style={{ textAlign: 'center', color: '#555' }}>No channels found</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChannelFilter;
